import React, {useState} from "react"
import styled from 'styled-components';
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from '../.';

// import Form from '../components/SignUp/Form';
// import Success from '../components/SignUp/Success';

import { graphql } from "gatsby"
import Img from "gatsby-image"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

const Backdrop = styled.div`
    width: 100%;
    padding: 0rem 2rem;
    position: relative;
    text-align: center;
    overflow: hidden; 
    @media only screen and (max-width: 500px) {
        /* padding: 0rem 1rem; */
        padding: 0rem;
        margin: 0rem;
    }
`

const TitleBar = styled.div`
    margin: 2rem;
    padding: 2rem 2rem;

    background: #f0f0f0;
    text-align: center;
    justify-content: center;
    font-size: 30px;
    @media only screen and (max-width: 500px) {
        /* background: #fbfbfb; */
        margin: 1rem 0rem;
    }

    ${props => {
        switch (props.color){
            case "blue":
                return 'background: rgb(0, 153, 255);';
            case "darkblue":
                return 'background: #0f2f4f;';
            case "purple":
                return 'background: #a081fc;';
            case "green":
                return 'background: #35c483;';}
            return null;
        }
    }
`

const Bar = styled.div`
    margin: 2rem;
    padding: 1rem 2rem;
    padding-top: 0rem;
    background: #f0f0f0;
    color: ${props => props.theme.darkCerulean};
    @media only screen and (max-width: 500px) {
        /* background: #fbfbfb; */
        margin: 1rem 0rem;
    }
    ${props => {
        switch (props.color){
            case "blue":
                return 'background: linear-gradient(to right, rgb(0, 153, 255) , skyblue);';
            case "purple":
                return 'background: #a081fc;';
            case "darkblue":
                return 'background: #0f2f4f;';
            case "pink":
                return 'background: #E75480;';
            case "indigo":
                return 'background: indigo;';
            case "green":
                return 'background: #35c483;';
            case "orange":
                return 'background: orange;';
            }
            return null;
        }
    }

    ${props => {
        switch (props.margbottom){
            case "zero":
                return 'margin-bottom:0;';
            }
            return null;
        }
    }
`

const Container = styled.div`
    justify-content: center;
    margin: 2rem 0rem;
    @media only screen and (max-width: 500px) {
        margin: 0rem;
    }
`

const Link1 = styled(Link)`
    color: #fbfbfb;
    text-decoration: none;
    &:hover {
        color: #ffffff;
        text-decoration: none;
        text-shadow: 0 0 25px #ffffff;
    }
`

const IndexPage = ({data}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);

    function submitForm() {
        setIsSubmitted(true);
    }
    return(
        <Layout>
        <SEO title="Home" />
        <Container>
            <TitleBar>
                <Content.Subtitle color='black'>Technology. Language. Entrepreneurship.</Content.Subtitle>
            </TitleBar>
            
            <Backdrop>
                <div align='center'>
                    <div style={{ maxWidth: `700px`}}>
                        <Img
                            title="Lady coder."      
                            alt="Lady coder."
                            fluid={data.file.childImageSharp.fluid}
                        />
                    </div>
                </div>
            </Backdrop>
            <Bar color='blue'>
            <br/>
                <Content.Text color='white'>Web Development Company: </Content.Text>
                <br/>
                <Content.Title align='center' color='white'><Link1 to='http://www.duolivre.com' target="_blank" rel="noopener noreferrer">DuoLivre, LLC</Link1> <FontAwesomeIcon icon={faExternalLinkAlt}/></Content.Title>  
                <Content.Text align='center' color='white'><i>Web design, development, and marketing. Not currently accepting new clients.</i></Content.Text>     
                <br/>
                {/* <Content.Text>Want to learn more about the Gabo app?</Content.Text>            
                {!isSubmitted ? 
                    <Form submitForm={submitForm}/> :
                    <Success/>
                } */}
            </Bar>
            {/* <Bar margbottom="zero" color='blue'>
                <br/>
                <Content.Text color='white'>Language App Project: </Content.Text>
                <br/>
                <Content.Title align='center' color='white'><Link1 to='https://www.spanishlit.com' target="_blank" rel="noopener noreferrer">The Gabo App</Link1> <FontAwesomeIcon icon={faExternalLinkAlt}/></Content.Title>  
                <Content.Text align='center' color='white'><i>Your fix of daily foreign language and literature.</i></Content.Text>          
                <br/>
            </Bar> */}
        </Container>
        </Layout>
    )
}

export default IndexPage

export const query = graphql`  
  query {    
    file(relativePath: {eq: "DevFocus.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;